.slider-container {
  overflow: hidden;
}

.slide {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide > .overlay1 {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px 60px;
  top: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide > .overlay1 > .box-1 {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.slide > .overlay1 > .box-1 > h1 {
  font-family: Mupen;
  font-size: 47.45px;
  font-weight: 400;
  line-height: 61.09px;
  letter-spacing: 0.07em;
  color: #ffffff;
}

.slide > .overlay1 > .box-1 > p {
  font-family: Campton;
  font-size: 24px;
  font-weight: 500;
  line-height: 27.94px;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.slide > .overlay1 > .box-1 > button {
  width: 236px;
  height: 74px;
  padding: 9px 31px 9px 31px;
  gap: 10px;
  border-radius: 90px;
  background-color: #f2bb44;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: #000000;
}

.slide > .overlay1 > .box-2 {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 430px;
}

.slide > .overlay1 .box-2 > svg {
  animation: moveUpDown 1s infinite;
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.slider-container .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.slider-container .slick-dots li {
  margin: 0 5px;
  list-style: none;
  display: inline-block;
}

.slider-container .slick-dots li button {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.slider-container .slick-dots li.slick-active button {
  background-color: #f2bb44;
}

@media screen and (max-width: 768px) {
  .slide img {
    object-position: 17% center;
  }
  .slide > .overlay1 {
    padding: 10px;
  }
  .slide > .overlay1 > .box-2 {
    display: none;
  }

  .slide > .overlay1 > .box-1 {
    width: 600px;
    height: 430.65px;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  .slide > .overlay1 > .box-1 > h1 {
    width: 241.03px;
    font-family: Mupen;
    font-size: 19.06px;
    font-weight: 400;
    line-height: 24.54px;
    letter-spacing: 0.07em;
    text-align: left;
  }

  .slide > .overlay1 > .box-1 > p {
    width: 270px;
    font-family: Campton;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.64px;
    letter-spacing: -0.04em;
    text-align: left;
  }

  .slide {
    height: 500px;
  }

  .slide > .overlay1 > .box-1 > button {
    width: 170.92px;
    height: 43.67px;
    padding: 6px 19px 6px 19px;
    font-family: Poppins;
    font-size: 14.32px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.pay-over-slider > .cancel {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #f2bb43;
  color: #87ba55;
  cursor: pointer;
}
