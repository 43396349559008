.slider-wrapper {
  height: 90vh; 
}

.slider-slide {
  height: 100%;
  width: 100%;
}

.slider-slide > .slider-image {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: center;
  padding: 10px;
}

.slick-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slick-dots li {
  margin: 0 5px;
  list-style: none;
  display: inline-block;
}

.slick-dots li button {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background-color: #b3924b;
}

@media screen and (max-width:768px) {
  .slider-wrapper{
    height: auto;
  }
  .slider-slide > .slider-image{
    padding: 0%;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
