* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar-container {
  width: 100%;
  height: 200px;
  background-color: transparent;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  top: 0%;
  z-index: 100;
}

.navbar-container > a {
  display: flex;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    height: auto;
    padding: 10px;
    justify-content: flex-start;
  }

 

  .navbar-container > a > svg {
    width: fit-content;
    height: 40px;
    width: 120px;
  }
}


