.qoute {
  width: fit-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.qoute > h1 {
  font-family: ThickCampton;
  font-size: 30.84px;
  font-weight: 900;
  color: #f2bb44;
  margin-bottom: 20px;
}

.qoute > p {
  font-family: Campton;
  font-size: 40px;
  font-weight: 300;
  line-height: 46.55px;
  text-align: left;
  color: #ffffff;
}

@media screen and (max-width: 896px) {
  .qoute > h1 {
    width: 177.74px;
    font-family: ThickCampton;
    font-size: 10.55px;
    font-weight: 900;
    line-height: 12.27px;
    text-align: left;
    margin-bottom: 10px;
  }

  .qoute > p {
    width: 150px;
    font-family: Campton;
    font-size: 10.59px;
    font-weight: 300;
    line-height: 12.32px;
    text-align: left;
  }
}
