* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("/public/CamptonLight.otf");
}

@font-face {
  font-family: Mupen;
  src: url("/public/Mupen-Regular.otf");
}

@font-face {
  font-family: Poppins;
  src: url("/public/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat;
  src: url("/public/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: ThickCampton;
  src: url("/public/CamptonBold.otf");
}

.custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #0000;
  border-right-color: #f2bb4497;
  position: relative;
  animation: s4 1s infinite linear;
}
.custom-loader:before,
.custom-loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.custom-loader:after {
  animation-duration: 4s;
}

@keyframes s4 {
  100% {
    transform: rotate(1turn);
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(18, 18, 18, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
}

.scroll-to-top:hover {
  background-color: #87ba55;
}

.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2bb43;
}

.home-container > .banner {
  width: 100%;
  height: 100vh;
}

.home-container > .who-are-we {
  margin: 100px 0px;
  width: 937px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-container > .who-are-we > h1 {
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.25px;
  text-align: center;
  color: #000000;
}

.home-container > .who-are-we > p {
  font-family: Campton;
  font-size: 24px;
  font-weight: 400;
  line-height: 27.94px;
  text-align: center;
  color: #000000;
}

.home-container > .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container > .mission {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0px;
  gap: 30px;
  margin: 40px 0px;
  position: relative;
}

.home-container > .mission > .group {
  position: absolute;
  top: -15%;
}

.home-container > .mission > h1 {
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.25px;
  text-align: center;
  width: 645px;
  z-index: 1;
}

.home-container > .mission > p {
  font-family: Campton;
  font-size: 24px;
  font-weight: 500;
  line-height: 27.94px;
  text-align: center;
  width: 914px;
  z-index: 1;
}

@media screen and (max-width: 896px) {
  .home-container > .who-are-we {
    width: 100%;
  }

  .home-container > .who-are-we > h1 {
    font-family: Mupen;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.75px;
    text-align: center;
  }

  .home-container > .banner {
    height: fit-content;
  }
  .home-container > .mission > h1 {
    font-family: Mupen;
    font-size: 30px;
    font-weight: 400;
    line-height: 47.25px;
    text-align: center;
    width: 100%;
  }

  .home-container > .mission > p {
    font-family: Campton;
    font-size: 15px;
    line-height: 20.48px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  .home-container > .mission > .group {
    width: 100%;
    top: 15%;
  }

  .home-container > .mission > .arrow-mission {
    position: absolute;
    top: 100%;
  }

  .home-container > .mission {
    padding: 20px;
  }

  .home-container > .banner > .box-2 > svg {
    height: 30px;
  }

  .home-container > .who-are-we > h1 {
    font-size: 30px;
  }

  .home-container > .who-are-we {
    padding: 20px;
  }

  .home-container > .who-are-we > p {
    font-family: Campton;
    font-size: 15px;
    line-height: 20.48px;
    font-weight: 500;
    text-align: center;
  }
}

.home-container > .our-reason {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  margin: 80px 0px;
  padding-left: 40px;
  overflow: hidden;
}

.home-container > .our-reason > .wrapper {
  overflow-x: scroll;
  position: relative;
  height: 653px;
  overflow-y: hidden;
  background: #87ba55;
  display: flex;
  padding: 40px;
}

.home-container > .our-reason > .wrapper::-webkit-scrollbar {
  display: none;
}

.home-container > .our-reason > .wrapper > .box-1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 488px;
}

.home-container > .our-reason > .wrapper > .box-1 > svg {
  width: 433px;
}

.home-container > .our-reason > .wrapper > .box-1 > h1 {
  height: 169px;
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 84.77px;
  color: #000;
  width: 433px;
}

.home-container > .our-reason > .wrapper > .box-1 > p {
  height: 129px;
  font-family: Campton;
  font-size: 22px;
  font-weight: 500;
  line-height: 25.61px;
  color: #000;
  width: 429px;
}

.home-container > .our-reason > .wrapper > .box-1 > .p {
  height: 101px;
  font-family: Campton;
  font-size: 22px;
  font-weight: 500;
  line-height: 25.61px;
}

.home-container > .our-reason > .wrapper > .box-2 {
  width: 350px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

.home-container > .our-reason > .wrapper > .box-2 > img {
  height: 100%;
}

.home-container > .our-reason > .wrapper > .box-3 {
  max-width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  margin-left: 50px;
}

.home-container > .our-reason > .wrapper > .box-3 > .oga {
  animation: moveLeftRight 1s infinite;
}

@keyframes moveLeftRight {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
}

.home-container > .our-reason > .wrapper > .box-3 > p {
  width: 411px;
  height: 104px;
  font-family: Campton;
  font-size: 22px;
  font-weight: 500;
  line-height: 25.61px;
}

.home-container > .our-reason > .wrapper > .box-4 {
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 150px;
}

.home-container > .our-reason > .wrapper > .box-4 > img {
  transform: translateY(70px);
  object-fit: contain;
  z-index: 100%;
}

.home-container > .our-reason > .wrapper > .box-4 > p {
  width: 468px;
  height: 122px;
  font-family: Campton;
  font-size: 22px;
  font-weight: 500;
  line-height: 25.61px;
}

.home-container > .our-reason > .wrapper > .box-4 > svg {
  transform: translateY(210px);
  z-index: 1;
}

.home-container > .our-reason > .wrapper .vic11 {
  position: absolute;
  top: -12%;
  left: 27%;
  z-index: 1;
}

.home-container > .our-reason > .wrapper > .below {
  position: absolute;
  bottom: -7%;
  left: 10%;
}
.home-container > .our-reason > .wrapper > .small {
  position: absolute;
  bottom: -6%;
  left: 80%;
}

.home-container > .our-reason > .wrapper > .box-3 > .okay {
  display: none;
}

.line {
  display: none;
}

@media screen and (max-width: 896px) {
  .line {
    display: block;
    width: 70%;
    height: 5px;
    background-color: #f2bb43;
    margin: auto;
  }
  .home-container > .our-reason {
    padding: 0px;
    margin: 7%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .home-container > .our-reason > .wrapper {
    flex-direction: column;
    height: fit-content;
    overflow: hidden;
    width: 100%;
    padding: 0%;
  }

  .home-container > .our-reason > .wrapper > .box-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px;
    gap: 20px;
  }

  .home-container > .our-reason > .wrapper > .box-1 > h1 {
    width: 100%;
    height: 56px;
    font-family: Mupen;
    font-size: 30px;
    font-weight: 400;
    line-height: 28.26px;
    text-align: center;
    padding: 20px 0px;
  }

  .home-container > .our-reason > .wrapper > .box-1 > p {
    font-family: Campton;
    text-align: center;
    font-size: 15px;
    line-height: 20.48px;
    font-weight: 400;
    padding: 0px 20px;
    height: fit-content;
  }

  .home-container > .our-reason > .wrapper > .box-1 > svg {
    width: 70%;
  }

  .home-container > .our-reason > .wrapper > .box-1 > .p {
    font-family: Campton;
    text-align: center;
    font-size: 15px;
    line-height: 20.48px;
    font-weight: 400;
    padding: 0px 20px;
  }

  .home-container > .our-reason > .wrapper > .box-2 {
    position: relative;
    top: -87px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }

  .home-container > .our-reason > .wrapper > .box-2 > img {
    width: 100%;
    background-size: cover;
  }

  .home-container > .our-reason > .wrapper > .box-3 {
    width: 100%;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .home-container > .our-reason > .wrapper > .box-3 > p {
    text-align: center;
    height: fit-content;
    font-family: Campton;
    text-align: center;
    font-size: 15px;
    line-height: 20.48px;
    padding: 0px 20px;
  }

  .home-container > .our-reason > .wrapper > .box-3 > .oga {
    display: none;
  }

  .home-container > .our-reason > .wrapper > .box-3 > .okay {
    width: 70%;
  }
  .home-container > .our-reason > .wrapper > .box-4 {
    width: 100%;
    margin: 0;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    height: 350px;
  }

  .home-container > .our-reason > .wrapper > .box-4 > p {
    text-align: center;
    height: auto;
    text-align: center;
    font-size: 15px;
    line-height: 20.48px;
    padding: 0px 50px;
  }

  .home-container > .our-reason > .wrapper > .box-4 > img {
    width: 100%;
    transform: translateY(-10px);
  }

  .home-container > .our-reason > .wrapper .vic11 {
    position: absolute;
    top: 17%;
    width: 100px;
    height: 100px;
    transform: translateX(270px);
  }

  .home-container > .our-reason > .wrapper > .below {
    position: absolute;
    top: 16%;
    left: 2%;
    height: 100px;
    width: 100px;
  }
  .home-container > .our-reason > .wrapper > .small {
    position: absolute;
    top: 68%;
    left: -12%;
  }
}

.home-container > .video-con {
  width: 95%;
  height: 574;
  background-image: url("/src/assets/Rectangle\ 36@2x.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-container > .video-con > .play {
  position: absolute;
  cursor: pointer;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.6);
}

.overlay > svg {
  position: absolute;
  top: 3%;
  left: 5%;
  cursor: pointer;
  z-index: 100;
}

@media screen and (max-width: 896px) {
  .home-container > .video-con {
    height: fit-content;
    background-size: cover;
  }

  .home-container > .video-con > .on {
    height: 250px;
  }
}

.ga {
  width: 95%;
  margin: 40px 0px;
}

.home-container > .ga > h1 {
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.23px;
  text-align: left;
  color: #000000;
}

.gallary-images {
  width: 95%;
  margin-bottom: 10px;
  z-index: 100;
}

.banner-below {
  width: 95%;
  height: 80vh;
  background-image: url("https://i.ibb.co/PFW3NWR/Rectangle-31-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: 70px;
  padding: 20px;
  overflow: hidden;
}

.banner-below > .over {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.banner-below > .over > .box1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 150px;
}

.banner-below > .over > .box2 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.banner-below > svg {
  transform: translateX(100px);
  position: absolute;
  top: -32%;
}

@media screen and (max-width: 896px) {
  .home-container > .ga > h1 {
    font-size: 30px;
  }
  .gallary-images {
    margin-bottom: 30px;
  }

  .banner-below {
    width: 96%;
    height: fit-content;
    background-size: contain;
    padding: 0%;
    margin-top: 50px;
  }

  .banner-below > .over {
    width: 100%;
    height: 200px;
    padding: 0;
  }

  .banner-below > .over > .box1 {
    width: 100%;
    height: 100%;
    margin: 0%;
    padding: 20px;
    gap: 20px;
    justify-content: flex-end;
  }

  .banner-below > svg {
    height: 400px;
    width: 97%;
    transform: translateY(-10px);
  }

  .banner-below > .over > .box2 {
    padding: 20px;
  }
}

.our-sponsorer {
  width: 95%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 160px;
}

.our-sponsorer > h1 {
  width: 724px;
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.25px;
  text-align: center;
}

.our-sponsorer > p {
  width: 937px;
  font-family: Campton;
  font-size: 24px;
  font-weight: 400;
  line-height: 27.94px;
  text-align: center;
}

.sponsor-banner {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 20px;
}

.sponsor-banner > img {
  height: 100%;
}

@media screen and (max-width: 896px) {
  .our-sponsorer {
    width: 100%;
    height: auto;
    padding: 40px 0px;
    margin-top: 50px;
  }

  .our-sponsorer > h1 {
    width: auto;
    font-family: Mupen;
    font-size: 30px;
    font-weight: 400;
    line-height: 36.75px;
    text-align: center;
  }

  .our-sponsorer > p {
    width: 316px;
    font-family: Campton;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.48px;
    text-align: center;
  }
}

.work-for {
  width: 95%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}

.work-for > .box {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.work-for > .box2 {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-left: 50px;
}

.work-for > .box2 > h1 {
  width: 469px;
  height: 191px;
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.23px;
}

.work-for > .box2 > p {
  font-family: Campton;
  font-size: 24px;
  font-weight: 400;
  line-height: 27.94px;
}

.work-for > .box2 > button {
  width: 236px;
  height: 74px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #315f04;
  border-radius: 56px;
  border: none;
  cursor: pointer;
}

.work-for > .box > img {
  width: 624px;
  height: 673.92px;
}

@media screen and (max-width: 896px) {
  .work-for {
    width: 100%;
    flex-direction: column;
    height: fit-content;
    padding: 20px;
    gap: 20px;
    margin-bottom: 30px;
  }

  .work-for > .box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .work-for > .box2 {
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 10px 0px;
  }

  .work-for > .box > img {
    width: 100%;
    object-fit: contain;
    height: auto;
  }

  .work-for > .box2 > h1 {
    width: 100%;
    height: 26px;
    font-family: Mupen;
    font-size: 30px;
    font-weight: 400;
    line-height: 25.74px;
    text-align: center;
  }

  .work-for > .box2 > p {
    width: 320px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.48px;
    text-align: center;
  }
}

.form {
  width: 934px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 70px;
}

.form > form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
}

.form > form > h1 {
  font-family: Mupen;
  font-size: 60px;
  font-weight: 400;
  line-height: 77.23px;
  text-align: center;
  position: absolute;
  top: -25%;
}

.form > form > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.form > form > .in input {
  width: 100%;
  height: 72px;
  background-color: #d9d9d9;
  padding-left: 15px;
  border: none;
  outline: none;
  font-family: Montserrat;
}

.form > form > input {
  width: 100%;
  height: 72px;
  background-color: #d9d9d9;
  padding-left: 15px;
  border: none;
  outline: none;
  font-family: Montserrat;
}

.form > form > textarea {
  padding: 15px;
  background-color: #d9d9d9;
  border: none;
  outline: none;
  font-family: Montserrat;
}

.form > form > button {
  width: 300px;
  padding: 15px 40px;
  border: none;
  border-radius: 56px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #315f04;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form > form > button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.form > form > p {
  color: #87ba55;
  font-family: Montserrat;
  font-size: 16px;
}

.form > form > span {
  color: rgb(228, 41, 41);
  font-family: Montserrat;
  font-size: 16px;
}

.form > svg {
  position: absolute;
  top: -15%;
  height: 600px;
  left: 0%;
}

@media screen and (max-width: 896px) {
  .form {
    width: 100%;
    padding: 20px;
  }

  .form > svg {
    display: none;
  }

  .form > form {
    width: 100%;
    align-items: center;
  }

  .form > form > .in {
    flex-direction: column;
  }

  .form > form > .in > input {
    height: 40px;
  }

  .form > form > input {
    height: 40px;
  }

  .form > form > textarea {
    width: 100%;
    min-height: 121px;
  }

  .form > form > h1 {
    font-family: Mupen;
    font-size: 30px;
    font-weight: 400;
    line-height: 25.74px;
    text-align: center;
    top: -40px;
  }
}

.footer {
  width: 100%;
  height: 400px;
  background-color: #000000;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer > .box1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.footer > .box1 > p {
  font-family: Montserrat;
  font-size: 17.73px;
  font-weight: 400;
  line-height: 26.49px;
  color: #ffffff;
  width: 375.84px;
  height: 52px;
}

.footer > .box2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
}

.footer > .box2 > p {
  font-family: Montserrat;
  font-size: 19.83px;
  font-weight: 400;
  line-height: 35.15px;
  text-align: right;
  width: 264px;
  color: white;
}

.footer > .box2 > .social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer > .box2 > .p {
  display: none;
}

.footer > .box2 > .social > a {
  width: 42.25px;
  height: 38.19px;
  text-decoration: none;
}

.footer > .box2 > .social > a > svg {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 896px) {
  .footer {
    flex-direction: column;
    height: fit-content;
    padding: 70px 20px;
    height: fit-content;
    gap: 40px;
  }

  .footer > .box1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .footer > .box1 > p {
    display: none;
  }

  .footer > .box2 {
    width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
  }

  .footer > .box2 > p {
    font-family: Montserrat;
    font-size: 18.98px;
    font-weight: 400;
    line-height: 23.14px;
    text-align: center;
  }

  .footer > .box2 > .social > a {
    width: 30.99px;
    height: 30.8px;
  }

  .footer > .box2 > .p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    margin-top: 40px;
    text-align: center;
  }
}

.pay-over > .card > .cancel {
  position: absolute;
  left: 20px;
  top: 10px;
  background-color: #f2bb43;
  color: #87ba55;
  cursor: pointer;
}

.pay-over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background-color: rgba(18, 18, 18, 0.6);
}

.pay-over > .card {
  width: 30%;
  background-color: #87ba55;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 40px;
}

.pay-over > .card > .banking-svg {
  position: absolute;
  height: 300px;
}

.pay-over > .card > h1 {
  font-family: ThickCampton;
  font-size: 26px;
  font-weight: 700;
  line-height: 41.9px;
  letter-spacing: 0.09em;
  color: #315f04;
  z-index: 1;
}

.pay-over > .card > p {
  font-family: ThickCampton;
  font-size: 28.94px;
  font-weight: 600;
  line-height: 33.67px;
  text-align: center;
  color: #fdfdfd;
  z-index: 1;
}

.pay-over > .card > .inner {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.pay-over > .card > .inner > h1 {
  font-family: ThickCampton;
  font-size: 30px;
  font-weight: 600;
  line-height: 72.8px;
  text-align: center;
  color: #fdfdfd;
  z-index: 1;
}

.pay-over > .card > .inner > img {
  height: 60px;
}

.pay-over > .card > .food {
  z-index: 1;
  height: 150px;
}

.pay-over > .card > button {
  padding: 10px 30px;
  border-radius: 77px;
  background-color: #d9d9d9;
  font-family: ThickCampton;
  font-size: 15px;
  font-weight: 600;
  color: #315f04;
  border: none;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

@media screen and (max-width: 896px) {
  .pay-over > .card {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
  .pay-over > .banking-svg {
    width: 90%;
  }

  .pay-over > .card > p {
    font-size: 25px;
  }
}
